<template>
  <v-app :class="`locale-${locale}`">
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <float-alerts />

    <dashboard-core-view />

    <phone-confirmation />
  </v-app>
</template>

<script>
export default {
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar'),
    DashboardCoreDrawer: () => import('./components/core/Drawer'),
    DashboardCoreView: () => import('./components/core/View'),
    FloatAlerts: () => import('@/components/FloatAlerts'),
    PhoneConfirmation: () => import('@/components/PhoneConfirmation'),
  },

  data: () => ({
    expandOnHover: false,
    intervals: {
      clients: null,
      users: null,
      devices: null,
    },
  }),

  computed: {
    locale () {
      return this.$i18n.locale
    },
  },

  created () {
    this.getClients()
    this.getUsers()
    this.getDevices()
    this.getUsersDevices()
    // this.setReactiveTime()
  },

  methods: {
    getClients () {
      this.$store.dispatch('clients/get')
    },
    getUsers () {
      this.$store.dispatch('users/get')
    },
    async getDevices () {
      await this.$store.dispatch('devices/get')
      // console.log('got devices')
      // clearInterval(this.intervals.devices)
      // this.intervals.devices = setInterval(async () => {
      //   await this.$store.dispatch('devices/get')
      // }, 10000)
    },
    getUsersDevices () {
      this.$store.dispatch('usersDevices/get')
    },
    // setReactiveTime () {
    //   this.$store.dispatch('time/interval')
    // },
  },
}
</script>
